import React, { useEffect, useRef, useState } from "react";
import { Menu, Skeleton } from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { layoutSettingsVar } from "../../apollo/local/cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import { isAdmin, isSuperAdmin } from "../../acl/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faUsersLine,
   faGauge,
   faGears,
   faHandPointUp,
   faServer,
   faStream,
   faBug,
} from "@fortawesome/free-solid-svg-icons";
import { GQL_QUERY_ME } from "../../apollo/query/user";
import { startsWith } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { getAdminPath } from "../../util/router";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, user }) => {
   const history = useHistory();
   const location = useLocation();

   //assicura first load senza ricaricare la sidebar
   const sidebarLoaded = useRef(null);
   const [selectedKeys, setSelectedKeys] = useState([]);

   const { loading } = useQuery(GQL_QUERY_ME, {
      onCompleted() {
         if (!sidebarLoaded.current) {
            sidebarLoaded.current = true;
         }
      },
   });

   let { themeType } = useReactiveVar(layoutSettingsVar);

   //Selected menu item highlight
   useEffect(() => {
      const pathname = location.pathname;

      const menuKeys = [
         getAdminPath("dashboard"),
         getAdminPath("requests"),
         getAdminPath("input-tracks"),
         getAdminPath("output-tracks"),
         getAdminPath("users"),
         getAdminPath("settings"),
      ];

      menuKeys.forEach((key) => {
         if (pathname === key || startsWith(pathname, key + "/")) {
            setSelectedKeys([key]);
            return;
         }
      });
   }, [location]);

   //

   if (!sidebarLoaded.current && loading) {
      return <Skeleton active={true} />;
   }

   let items = [
      {
         key: getAdminPath("dashboard"),
         icon: <FontAwesomeIcon icon={faGauge} />,
         label: <IntlMessages id="sidebar.dashboard" />,
      },
   ];

   let siatelChildren = [
      {
         key: getAdminPath("requests"),
         icon: <FontAwesomeIcon icon={faHandPointUp} />,
         label: <IntlMessages id="sidebar.requests" />,
      },
      {
         key: getAdminPath("input-tracks"),
         icon: <FontAwesomeIcon icon={faServer} />,
         label: <IntlMessages id="sidebar.input-tracks" />,
      },
      {
         key: getAdminPath("output-tracks"),
         icon: <FontAwesomeIcon icon={faStream} />,
         label: <IntlMessages id="sidebar.output-tracks" />,
      },
   ];

   if (isAdmin(user)) {
      let adminChildren = [
         {
            key: getAdminPath("users"),
            icon: <FontAwesomeIcon icon={faUsersLine} />,
            label: <IntlMessages id="sidebar.users" />,
         },
         {
            key: getAdminPath("settings"),
            icon: <FontAwesomeIcon icon={faGears} />,
            label: <IntlMessages id="sidebar.settings" />,
         },
      ];

      if (isSuperAdmin(user)) {
         adminChildren.push({
            key: getAdminPath("debug"),
            icon: <FontAwesomeIcon icon={faBug} />,
            label: <IntlMessages id="sidebar.debug" />,
         });
      }

      items = [
         ...items,
         {
            type: "group",
            label: <IntlMessages id="sidebar.siatel" />,
            children: siatelChildren,
         },
         {
            type: "group",
            label: <IntlMessages id="sidebar.admin" />,
            children: adminChildren,
         },
      ];
   }

   return (
      <>
         <SidebarLogo
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
         />

         <div
            className="gx-sidebar-content"
            style={{ height: "calc(100vh - 72px)" }}
         >
            <CustomScrollbars className="gx-layout-sider-scrollbar_todo">
               <Menu
                  className="gx-mt-5"
                  items={items}
                  selectedKeys={selectedKeys}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  style={{ overflowX: "hidden" }}
                  onSelect={(e) => {
                     setSelectedKeys([e.key]);
                     history.push(e.key);
                  }}
               />
            </CustomScrollbars>
         </div>
      </>
   );
};

export default SidebarContent;
