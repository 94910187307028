export const Roles = {
   SUPERADMIN: "SUPERADMIN",
   ADMIN: "ADMIN",
};

export const ACLSubject = {
   USER: "user",
   REQUEST: "request",
   INPUT_TRACK: "input_track",
   OUTPUT_TRACK: "output_track",
};

export const RequestStatusType = {
   UPLOADED: "UPLOADED",
   VALIDATING: "VALIDATING",
   VALIDATED: "VALIDATED",
   VALIDATION_ERROR: "VALIDATION_ERROR",
   INPUT_GENERATION_QUEUE: "INPUT_GENERATION_QUEUE",
   INPUT_GENERATION: "INPUT_GENERATION",
};

export const InputTrackStatusType = {
   INPUT_GENERATED: "INPUT_GENERATED",
   OUTPUT_IMPORT_QUEUE: "OUTPUT_IMPORT_QUEUE",
   OUTPUT_IMPORTING: "OUTPUT_IMPORTING",
   OUTPUT_IMPORTED: "OUTPUT_IMPORTED",
   OUTPUT_IMPORT_ERROR: "OUTPUT_IMPORT_ERROR",
   OUTPUT_DELETING_RECORD: "OUTPUT_DELETING_RECORD",
   OUTPUT_DELETING_RECORD_ERROR: "OUTPUT_DELETING_RECORD_ERROR",
};

export const RegistryKeyType = {
   PHYSICAL: "PHYSICAL",
   COMPANY: "COMPANY",
};
